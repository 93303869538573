export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/auth/login": [13,[3]],
		"/auth/password-set": [14,[3]],
		"/auth/signup": [15,[3]],
		"/(main)/envelopes": [6,[2]],
		"/(main)/envelopes/prepare": [7,[2]],
		"/(main)/envelopes/send": [8,[2]],
		"/(main)/generate": [9,[2]],
		"/(main)/profile": [10,[2]],
		"/(main)/support": [11,[2]],
		"/(main)/users": [12,[2]],
		"/(landing-page)/[lang=locale]": [5]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';